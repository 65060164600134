@tailwind base;
@tailwind components;
@tailwind utilities;

nextjs-portal {
  display: none;
}

.grayplaceholder::placeholder{
  color: rgb(75 85 99)
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px red;
  }
  50% {
    box-shadow: 0 0 20px red, 0 0 30px red;
  }
  100% {
    box-shadow: 0 0 5px red;
  }
}

video::-webkit-media-controls {
  transform: scale(-1, 1);
     -webkit-transform:scale(-1, 1); /* Safari and Chrome */
     -moz-transform:scale(-1, 1); /* Firefox */
 }
 video::-webkit-media-controls-enclosure {
  transform: scale(-1, 1);
     -webkit-transform:scale(-1, 1); /* Safari and Chrome */
     -moz-transform:scale(-1, 1); /* Firefox */
 }



.h-height--10 {
  height: 10px !important;
}
.h-height--16 {
  height: 16px !important;
}
.h-height--18 {
  height: 18px !important;
}
.h-height--20 {
  height: 20px !important;
}
.h-height--30 {
  height: 30px !important;
}
.h-height--35 {
  height: 35px !important;
}
.h-height--40 {
  height: 40px !important;
}
.h-height--50 {
  height: 50px !important;
}
.h-height--60 {
  height: 60px !important;
}
.h-height--70 {
  height: 70px !important;
}
.h-height--80 {
  height: 80px !important;
}
.h-height--90 {
  height: 90px !important;
}
.h-height--100 {
  height: 100px !important;
}
.h-height--120 {
  height: 120px !important;
}
.h-height--140 {
  height: 140px !important;
}
.h-height--160 {
  height: 160px !important;
}
.h-height--180 {
  height: 180px !important;
}
.h-height--200 {
  height: 200px !important;
}
.h-height--220 {
  height: 220px !important;
}
.h-height--240 {
  height: 240px !important;
}
.h-height--260 {
  height: 260px !important;
}
.h-height--280 {
  height: 280px !important;
}
.h-height--300 {
  height: 300px !important;
}
.h-height--350 {
  height: 350px !important;
}
.h-height--400 {
  height: 400px !important;
}
.h-height--450 {
  height: 450px !important;
}
.h-height--500 {
  height: 500px !important;
}
.h-height--600 {
  height: 600px !important;
}
.h-height--700 {
  height: 700px !important;
}
.h-height--800 {
  height: 800px !important;
}
.h-height--900 {
  height: 900px !important;
}
.h-height--1000 {
  height: 1000px !important;
}
.h-height--100vh {
  height: 100vh;
}

@media (max-width: 700px) {
  .h-height-m--10 {
    height: 10px !important;
  }
  .h-height-m--16 {
    height: 16px !important;
  }
  .h-height-m--18 {
    height: 18px !important;
  }
  .h-height-m--20 {
    height: 20px !important;
  }
  .h-height-m--30 {
    height: 30px !important;
  }
  .h-height-m--35 {
    height: 35px !important;
  }
  .h-height-m--40 {
    height: 40px !important;
  }
  .h-height-m--50 {
    height: 50px !important;
  }
  .h-height-m--60 {
    height: 60px !important;
  }
  .h-height-m--70 {
    height: 70px !important;
  }
  .h-height-m--80 {
    height: 80px !important;
  }
  .h-height-m--90 {
    height: 90px !important;
  }
  .h-height-m--100 {
    height: 100px !important;
  }
  .h-height-m--120 {
    height: 120px !important;
  }
  .h-height-m--140 {
    height: 140px !important;
  }
  .h-height-m--160 {
    height: 160px !important;
  }
  .h-height-m--180 {
    height: 180px !important;
  }
  .h-height-m--200 {
    height: 200px !important;
  }
  .h-height-m--220 {
    height: 220px !important;
  }
  .h-height-m--240 {
    height: 240px !important;
  }
  .h-height-m--260 {
    height: 260px !important;
  }
  .h-height-m--280 {
    height: 280px !important;
  }
  .h-height-m--300 {
    height: 300px !important;
  }
  .h-height-m--350 {
    height: 350px !important;
  }
  .h-height-m--400 {
    height: 400px !important;
  }
  .h-height-m--450 {
    height: 450px !important;
  }
  .h-height-m--500 {
    height: 500px !important;
  }
  .h-height-m--600 {
    height: 600px !important;
  }
  .h-height-m--700 {
    height: 700px !important;
  }
  .h-height-m--800 {
    height: 800px !important;
  }
  .h-height-m--900 {
    height: 900px !important;
  }
  .h-height-m--1000 {
    height: 1000px !important;
  }
  .h-height-m--100vh {
    height: 100vh !important;
    max-height: 100% !important;
  }
}
.h-width--100p {
  width: 100%;
}
.h-width--100vw {
  width: 100vw !important;
}
.h-width--10 {
  width: 10px !important;
  max-width: 100%;
}
.h-width--16 {
  width: 16px !important;
  max-width: 100%;
}
.h-width--18 {
  width: 18px !important;
  max-width: 100%;
}
.h-width--20 {
  width: 20px !important;
  max-width: 100%;
}
.h-width--30 {
  width: 30px !important;
  max-width: 100%;
}
.h-width--35 {
  width: 35px !important;
  max-width: 100%;
}
.h-width--40 {
  width: 40px !important;
  max-width: 100%;
}
.h-width--50 {
  width: 50px !important;
  max-width: 100%;
}
.h-width--60 {
  width: 60px !important;
  max-width: 100%;
}
.h-width--70 {
  width: 70px !important;
  max-width: 100%;
}
.h-width--80 {
  width: 80px !important;
  max-width: 100%;
}
.h-width--90 {
  width: 90px !important;
  max-width: 100%;
}
.h-width--100 {
  width: 100px !important;
  max-width: 100%;
}
.h-width--120 {
  width: 120px !important;
  max-width: 100%;
}
.h-width--140 {
  width: 140px !important;
  max-width: 100%;
}
.h-width--160 {
  width: 160px !important;
  max-width: 100%;
}
.h-width--180 {
  width: 180px !important;
  max-width: 100%;
}
.h-width--200 {
  width: 200px !important;
  max-width: 100%;
}
.h-width--220 {
  width: 220px !important;
  max-width: 100%;
}
.h-width--240 {
  width: 240px !important;
  max-width: 100%;
}
.h-width--260 {
  width: 260px !important;
  max-width: 100%;
}
.h-width--280 {
  width: 280px !important;
  max-width: 100%;
}
.h-width--300 {
  width: 300px !important;
  max-width: 100%;
}
.h-width--350 {
  width: 350px !important;
  max-width: 100%;
}
.h-width--400 {
  width: 400px !important;
  max-width: 100%;
}
.h-width--450 {
  width: 450px !important;
  max-width: 100%;
}
.h-width--500 {
  width: 500px !important;
  max-width: 100%;
}
.h-width--600 {
  width: 600px !important;
  max-width: 100%;
}
.h-width--700 {
  width: 700px !important;
  max-width: 100%;
}
.h-width--800 {
  width: 800px !important;
  max-width: 100%;
}
.h-width--900 {
  width: 900px !important;
  max-width: 100%;
}
.h-width--1000 {
  width: 1000px !important;
  max-width: 100%;
}

@media (max-width: 700px) {
  .h-width-m--100p {
    width: 100% !important;
  }
  .h-width-m--100vw {
    width: 100vw !important;
  }
  .h-width-m--10 {
    width: 10px !important;
    max-width: 100%;
  }
  .h-width-m--16 {
    width: 16px !important;
    max-width: 100%;
  }
  .h-width-m--18 {
    width: 18px !important;
    max-width: 100%;
  }
  .h-width-m--20 {
    width: 20px !important;
    max-width: 100%;
  }
  .h-width-m--30 {
    width: 30px !important;
    max-width: 100%;
  }
  .h-width-m--35 {
    width: 35px !important;
    max-width: 100%;
  }
  .h-width-m--40 {
    width: 40px !important;
    max-width: 100%;
  }
  .h-width-m--50 {
    width: 50px !important;
    max-width: 100%;
  }
  .h-width-m--60 {
    width: 60px !important;
    max-width: 100%;
  }
  .h-width-m--70 {
    width: 70px !important;
    max-width: 100%;
  }
  .h-width-m--80 {
    width: 80px !important;
    max-width: 100%;
  }
  .h-width-m--90 {
    width: 90px !important;
    max-width: 100%;
  }
  .h-width-m--100 {
    width: 100px !important;
    max-width: 100%;
  }
  .h-width-m--120 {
    width: 120px !important;
    max-width: 100%;
  }
  .h-width-m--140 {
    width: 140px !important;
    max-width: 100%;
  }
  .h-width-m--160 {
    width: 160px !important;
    max-width: 100%;
  }
  .h-width-m--180 {
    width: 180px !important;
    max-width: 100%;
  }
  .h-width-m--200 {
    width: 200px !important;
    max-width: 100%;
  }
  .h-width-m--220 {
    width: 220px !important;
    max-width: 100%;
  }
  .h-width-m--240 {
    width: 240px !important;
    max-width: 100%;
  }
  .h-width-m--260 {
    width: 260px !important;
    max-width: 100%;
  }
  .h-width-m--280 {
    width: 280px !important;
    max-width: 100%;
  }
  .h-width-m--300 {
    width: 300px !important;
    max-width: 100%;
  }
  .h-width-m--350 {
    width: 350px !important;
    max-width: 100%;
  }
  .h-width-m--400 {
    width: 400px !important;
    max-width: 100%;
  }
  .h-width-m--450 {
    width: 450px !important;
    max-width: 100%;
  }
  .h-width-m--500 {
    width: 500px !important;
    max-width: 100%;
  }
  .h-width-m--600 {
    width: 600px !important;
    max-width: 100%;
  }
  .h-width-m--700 {
    width: 700px !important;
    max-width: 100%;
  }
  .h-width-m--800 {
    width: 800px !important;
    max-width: 100%;
  }
  .h-width-m--900 {
    width: 900px !important;
    max-width: 100%;
  }
  .h-width-m--1000 {
    width: 1000px !important;
    max-width: 100%;
  }
}

.h-align--left {
  text-align: left;
}
.h-align--center {
  text-align: center;
}
.h-align--right {
  text-align: right;
}

@media (max-width: 700px) {
  .h-align-m--left {
    text-align: left;
  }
  .h-align-m--center {
    text-align: center;
  }
  .h-align-m--right {
    text-align: right;
  }
}
.h-margin---20 {
  margin: -20px !important;
}
.h-margin__v---20 {
  margin-top: -20px !important;
  margin-bottom: -20px !important;
}
.h-margin__h---20 {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.h-margin__b---20 {
  margin-bottom: -20px !important;
}
.h-margin__t---20 {
  margin-top: -20px !important;
}
.h-margin__l---20 {
  margin-left: -20px !important;
}
.h-margin__r---20 {
  margin-right: -20px !important;
}
.h-margin---15 {
  margin: -15px !important;
}
.h-margin__v---15 {
  margin-top: -15px !important;
  margin-bottom: -15px !important;
}
.h-margin__h---15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.h-margin__b---15 {
  margin-bottom: -15px !important;
}
.h-margin__t---15 {
  margin-top: -15px !important;
}
.h-margin__l---15 {
  margin-left: -15px !important;
}
.h-margin__r---15 {
  margin-right: -15px !important;
}
.h-margin---10 {
  margin: -10px !important;
}
.h-margin__v---10 {
  margin-top: -10px !important;
  margin-bottom: -10px !important;
}
.h-margin__h---10 {
  margin-left: -10px !important;
  margin-right: -10px !important;
}
.h-margin__b---10 {
  margin-bottom: -10px !important;
}
.h-margin__t---10 {
  margin-top: -10px !important;
}
.h-margin__l---10 {
  margin-left: -10px !important;
}
.h-margin__r---10 {
  margin-right: -10px !important;
}
.h-margin---5 {
  margin: -5px !important;
}
.h-margin__v---5 {
  margin-top: -5px !important;
  margin-bottom: -5px !important;
}
.h-margin__h---5 {
  margin-left: -5px !important;
  margin-right: -5px !important;
}
.h-margin__b---5 {
  margin-bottom: -5px !important;
}
.h-margin__t---5 {
  margin-top: -5px !important;
}
.h-margin__l---5 {
  margin-left: -5px !important;
}
.h-margin__r---5 {
  margin-right: -5px !important;
}
.h-margin--0 {
  margin: 0px !important;
}
.h-margin__v--0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.h-margin__h--0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.h-margin__b--0 {
  margin-bottom: 0px !important;
}
.h-margin__t--0 {
  margin-top: 0px !important;
}
.h-margin__l--0 {
  margin-left: 0px !important;
}
.h-margin__r--0 {
  margin-right: 0px !important;
}
.h-margin--5 {
  margin: 5px !important;
}
.h-margin__v--5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}
.h-margin__h--5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}
.h-margin__b--5 {
  margin-bottom: 5px !important;
}
.h-margin__t--5 {
  margin-top: 5px !important;
}
.h-margin__l--5 {
  margin-left: 5px !important;
}
.h-margin__r--5 {
  margin-right: 5px !important;
}
.h-margin--8 {
  margin: 8px !important;
}
.h-margin__v--8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.h-margin__h--8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.h-margin__b--8 {
  margin-bottom: 8px !important;
}
.h-margin__t--8 {
  margin-top: 8px !important;
}
.h-margin__l--8 {
  margin-left: 8px !important;
}
.h-margin__r--8 {
  margin-right: 8px !important;
}
.h-margin--10 {
  margin: 10px !important;
}
.h-margin__v--10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}
.h-margin__h--10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
.h-margin__b--10 {
  margin-bottom: 10px !important;
}
.h-margin__t--10 {
  margin-top: 10px !important;
}
.h-margin__l--10 {
  margin-left: 10px !important;
}
.h-margin__r--10 {
  margin-right: 10px !important;
}
.h-margin--15 {
  margin: 15px !important;
}
.h-margin__v--15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}
.h-margin__h--15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}
.h-margin__b--15 {
  margin-bottom: 15px !important;
}
.h-margin__t--15 {
  margin-top: 15px !important;
}
.h-margin__l--15 {
  margin-left: 15px !important;
}
.h-margin__r--15 {
  margin-right: 15px !important;
}
.h-margin--20 {
  margin: 20px !important;
}
.h-margin__v--20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}
.h-margin__h--20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.h-margin__b--20 {
  margin-bottom: 20px !important;
}
.h-margin__t--20 {
  margin-top: 20px !important;
}
.h-margin__l--20 {
  margin-left: 20px !important;
}
.h-margin__r--20 {
  margin-right: 20px !important;
}
.h-margin--25 {
  margin: 25px !important;
}
.h-margin__v--25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.h-margin__h--25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}
.h-margin__b--25 {
  margin-bottom: 25px !important;
}
.h-margin__t--25 {
  margin-top: 25px !important;
}
.h-margin__l--25 {
  margin-left: 25px !important;
}
.h-margin__r--25 {
  margin-right: 25px !important;
}
.h-margin--30 {
  margin: 30px !important;
}
.h-margin__v--30 {
  margin-top: 30px !important;
  margin-bottom: 30px !important;
}
.h-margin__h--30 {
  margin-left: 30px !important;
  margin-right: 30px !important;
}
.h-margin__b--30 {
  margin-bottom: 30px !important;
}
.h-margin__t--30 {
  margin-top: 30px !important;
}
.h-margin__l--30 {
  margin-left: 30px !important;
}
.h-margin__r--30 {
  margin-right: 30px !important;
}
.h-margin--40 {
  margin: 40px !important;
}
.h-margin__v--40 {
  margin-top: 40px !important;
  margin-bottom: 40px !important;
}
.h-margin__h--40 {
  margin-left: 40px !important;
  margin-right: 40px !important;
}
.h-margin__b--40 {
  margin-bottom: 40px !important;
}
.h-margin__t--40 {
  margin-top: 40px !important;
}
.h-margin__l--40 {
  margin-left: 40px !important;
}
.h-margin__r--40 {
  margin-right: 40px !important;
}
.h-margin--50 {
  margin: 50px !important;
}
.h-margin__v--50 {
  margin-top: 50px !important;
  margin-bottom: 50px !important;
}
.h-margin__h--50 {
  margin-left: 50px !important;
  margin-right: 50px !important;
}
.h-margin__b--50 {
  margin-bottom: 50px !important;
}
.h-margin__t--50 {
  margin-top: 50px !important;
}
.h-margin__l--50 {
  margin-left: 50px !important;
}
.h-margin__r--50 {
  margin-right: 50px !important;
}
.h-margin--60 {
  margin: 60px !important;
}
.h-margin__v--60 {
  margin-top: 60px !important;
  margin-bottom: 60px !important;
}
.h-margin__h--60 {
  margin-left: 60px !important;
  margin-right: 60px !important;
}
.h-margin__b--60 {
  margin-bottom: 60px !important;
}
.h-margin__t--60 {
  margin-top: 60px !important;
}
.h-margin__l--60 {
  margin-left: 60px !important;
}
.h-margin__r--60 {
  margin-right: 60px !important;
}
.h-margin--70 {
  margin: 70px !important;
}
.h-margin__v--70 {
  margin-top: 70px !important;
  margin-bottom: 70px !important;
}
.h-margin__h--70 {
  margin-left: 70px !important;
  margin-right: 70px !important;
}
.h-margin__b--70 {
  margin-bottom: 70px !important;
}
.h-margin__t--70 {
  margin-top: 70px !important;
}
.h-margin__l--70 {
  margin-left: 70px !important;
}
.h-margin__r--70 {
  margin-right: 70px !important;
}
.h-margin--80 {
  margin: 80px !important;
}
.h-margin__v--80 {
  margin-top: 80px !important;
  margin-bottom: 80px !important;
}
.h-margin__h--80 {
  margin-left: 80px !important;
  margin-right: 80px !important;
}
.h-margin__b--80 {
  margin-bottom: 80px !important;
}
.h-margin__t--80 {
  margin-top: 80px !important;
}
.h-margin__l--80 {
  margin-left: 80px !important;
}
.h-margin__r--80 {
  margin-right: 80px !important;
}
.h-margin--90 {
  margin: 90px !important;
}
.h-margin__v--90 {
  margin-top: 90px !important;
  margin-bottom: 90px !important;
}
.h-margin__h--90 {
  margin-left: 90px !important;
  margin-right: 90px !important;
}
.h-margin__b--90 {
  margin-bottom: 90px !important;
}
.h-margin__t--90 {
  margin-top: 90px !important;
}
.h-margin__l--90 {
  margin-left: 90px !important;
}
.h-margin__r--90 {
  margin-right: 90px !important;
}
.h-margin--100 {
  margin: 100px !important;
}
.h-margin__v--100 {
  margin-top: 100px !important;
  margin-bottom: 100px !important;
}
.h-margin__h--100 {
  margin-left: 100px !important;
  margin-right: 100px !important;
}
.h-margin__b--100 {
  margin-bottom: 100px !important;
}
.h-margin__t--100 {
  margin-top: 100px !important;
}
.h-margin__l--100 {
  margin-left: 100px !important;
}
.h-margin__r--100 {
  margin-right: 100px !important;
}
.h-margin--200 {
  margin: 200px !important;
}
.h-margin__v--200 {
  margin-top: 200px !important;
  margin-bottom: 200px !important;
}
.h-margin__h--200 {
  margin-left: 200px !important;
  margin-right: 200px !important;
}
.h-margin__b--200 {
  margin-bottom: 200px !important;
}
.h-margin__t--200 {
  margin-top: 200px !important;
}
.h-margin__l--200 {
  margin-left: 200px !important;
}
.h-margin__r--200 {
  margin-right: 200px !important;
}
.h-margin--250 {
  margin: 250px !important;
}
.h-margin__v--250 {
  margin-top: 250px !important;
  margin-bottom: 250px !important;
}
.h-margin__h--250 {
  margin-left: 250px !important;
  margin-right: 250px !important;
}
.h-margin__b--250 {
  margin-bottom: 250px !important;
}
.h-margin__t--250 {
  margin-top: 250px !important;
}
.h-margin__l--250 {
  margin-left: 250px !important;
}
.h-margin__r--250 {
  margin-right: 250px !important;
}
.h-margin--300 {
  margin: 300px !important;
}
.h-margin__v--300 {
  margin-top: 300px !important;
  margin-bottom: 300px !important;
}
.h-margin__h--300 {
  margin-left: 300px !important;
  margin-right: 300px !important;
}
.h-margin__b--300 {
  margin-bottom: 300px !important;
}
.h-margin__t--300 {
  margin-top: 300px !important;
}
.h-margin__l--300 {
  margin-left: 300px !important;
}
.h-margin__r--300 {
  margin-right: 300px !important;
}
.h-margin--auto {
  margin: auto;
}

@media (max-width: 700px) {
  .h-margin-m---20 {
    margin: -20px !important;
  }
  .h-margin-m__v---20 {
    margin-top: -20px !important;
    margin-bottom: -20px !important;
  }
  .h-margin-m__h---20 {
    margin-left: -20px !important;
    margin-right: -20px !important;
  }
  .h-margin-m__b---20 {
    margin-bottom: -20px !important;
  }
  .h-margin-m__t---20 {
    margin-top: -20px !important;
  }
  .h-margin-m__l---20 {
    margin-left: -20px !important;
  }
  .h-margin-m__r---20 {
    margin-right: -20px !important;
  }
  .h-margin-m---15 {
    margin: -15px !important;
  }
  .h-margin-m__v---15 {
    margin-top: -15px !important;
    margin-bottom: -15px !important;
  }
  .h-margin-m__h---15 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
  .h-margin-m__b---15 {
    margin-bottom: -15px !important;
  }
  .h-margin-m__t---15 {
    margin-top: -15px !important;
  }
  .h-margin-m__l---15 {
    margin-left: -15px !important;
  }
  .h-margin-m__r---15 {
    margin-right: -15px !important;
  }
  .h-margin-m---10 {
    margin: -10px !important;
  }
  .h-margin-m__v---10 {
    margin-top: -10px !important;
    margin-bottom: -10px !important;
  }
  .h-margin-m__h---10 {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
  .h-margin-m__b---10 {
    margin-bottom: -10px !important;
  }
  .h-margin-m__t---10 {
    margin-top: -10px !important;
  }
  .h-margin-m__l---10 {
    margin-left: -10px !important;
  }
  .h-margin-m__r---10 {
    margin-right: -10px !important;
  }
  .h-margin-m---5 {
    margin: -5px !important;
  }
  .h-margin-m__v---5 {
    margin-top: -5px !important;
    margin-bottom: -5px !important;
  }
  .h-margin-m__h---5 {
    margin-left: -5px !important;
    margin-right: -5px !important;
  }
  .h-margin-m__b---5 {
    margin-bottom: -5px !important;
  }
  .h-margin-m__t---5 {
    margin-top: -5px !important;
  }
  .h-margin-m__l---5 {
    margin-left: -5px !important;
  }
  .h-margin-m__r---5 {
    margin-right: -5px !important;
  }
  .h-margin-m--0 {
    margin: 0px !important;
  }
  .h-margin-m__v--0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .h-margin-m__h--0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }
  .h-margin-m__b--0 {
    margin-bottom: 0px !important;
  }
  .h-margin-m__t--0 {
    margin-top: 0px !important;
  }
  .h-margin-m__l--0 {
    margin-left: 0px !important;
  }
  .h-margin-m__r--0 {
    margin-right: 0px !important;
  }
  .h-margin-m--5 {
    margin: 5px !important;
  }
  .h-margin-m__v--5 {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
  .h-margin-m__h--5 {
    margin-left: 5px !important;
    margin-right: 5px !important;
  }
  .h-margin-m__b--5 {
    margin-bottom: 5px !important;
  }
  .h-margin-m__t--5 {
    margin-top: 5px !important;
  }
  .h-margin-m__l--5 {
    margin-left: 5px !important;
  }
  .h-margin-m__r--5 {
    margin-right: 5px !important;
  }
  .h-margin-m--8 {
    margin: 8px !important;
  }
  .h-margin-m__v--8 {
    margin-top: 8px !important;
    margin-bottom: 8px !important;
  }
  .h-margin-m__h--8 {
    margin-left: 8px !important;
    margin-right: 8px !important;
  }
  .h-margin-m__b--8 {
    margin-bottom: 8px !important;
  }
  .h-margin-m__t--8 {
    margin-top: 8px !important;
  }
  .h-margin-m__l--8 {
    margin-left: 8px !important;
  }
  .h-margin-m__r--8 {
    margin-right: 8px !important;
  }
  .h-margin-m--10 {
    margin: 10px !important;
  }
  .h-margin-m__v--10 {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }
  .h-margin-m__h--10 {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
  .h-margin-m__b--10 {
    margin-bottom: 10px !important;
  }
  .h-margin-m__t--10 {
    margin-top: 10px !important;
  }
  .h-margin-m__l--10 {
    margin-left: 10px !important;
  }
  .h-margin-m__r--10 {
    margin-right: 10px !important;
  }
  .h-margin-m--15 {
    margin: 15px !important;
  }
  .h-margin-m__v--15 {
    margin-top: 15px !important;
    margin-bottom: 15px !important;
  }
  .h-margin-m__h--15 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .h-margin-m__b--15 {
    margin-bottom: 15px !important;
  }
  .h-margin-m__t--15 {
    margin-top: 15px !important;
  }
  .h-margin-m__l--15 {
    margin-left: 15px !important;
  }
  .h-margin-m__r--15 {
    margin-right: 15px !important;
  }
  .h-margin-m--20 {
    margin: 20px !important;
  }
  .h-margin-m__v--20 {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
  }
  .h-margin-m__h--20 {
    margin-left: 20px !important;
    margin-right: 20px !important;
  }
  .h-margin-m__b--20 {
    margin-bottom: 20px !important;
  }
  .h-margin-m__t--20 {
    margin-top: 20px !important;
  }
  .h-margin-m__l--20 {
    margin-left: 20px !important;
  }
  .h-margin-m__r--20 {
    margin-right: 20px !important;
  }
  .h-margin-m--25 {
    margin: 25px !important;
  }
  .h-margin-m__v--25 {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }
  .h-margin-m__h--25 {
    margin-left: 25px !important;
    margin-right: 25px !important;
  }
  .h-margin-m__b--25 {
    margin-bottom: 25px !important;
  }
  .h-margin-m__t--25 {
    margin-top: 25px !important;
  }
  .h-margin-m__l--25 {
    margin-left: 25px !important;
  }
  .h-margin-m__r--25 {
    margin-right: 25px !important;
  }
  .h-margin-m--30 {
    margin: 30px !important;
  }
  .h-margin-m__v--30 {
    margin-top: 30px !important;
    margin-bottom: 30px !important;
  }
  .h-margin-m__h--30 {
    margin-left: 30px !important;
    margin-right: 30px !important;
  }
  .h-margin-m__b--30 {
    margin-bottom: 30px !important;
  }
  .h-margin-m__t--30 {
    margin-top: 30px !important;
  }
  .h-margin-m__l--30 {
    margin-left: 30px !important;
  }
  .h-margin-m__r--30 {
    margin-right: 30px !important;
  }
  .h-margin-m--40 {
    margin: 40px !important;
  }
  .h-margin-m__v--40 {
    margin-top: 40px !important;
    margin-bottom: 40px !important;
  }
  .h-margin-m__h--40 {
    margin-left: 40px !important;
    margin-right: 40px !important;
  }
  .h-margin-m__b--40 {
    margin-bottom: 40px !important;
  }
  .h-margin-m__t--40 {
    margin-top: 40px !important;
  }
  .h-margin-m__l--40 {
    margin-left: 40px !important;
  }
  .h-margin-m__r--40 {
    margin-right: 40px !important;
  }
  .h-margin-m--50 {
    margin: 50px !important;
  }
  .h-margin-m__v--50 {
    margin-top: 50px !important;
    margin-bottom: 50px !important;
  }
  .h-margin-m__h--50 {
    margin-left: 50px !important;
    margin-right: 50px !important;
  }
  .h-margin-m__b--50 {
    margin-bottom: 50px !important;
  }
  .h-margin-m__t--50 {
    margin-top: 50px !important;
  }
  .h-margin-m__l--50 {
    margin-left: 50px !important;
  }
  .h-margin-m__r--50 {
    margin-right: 50px !important;
  }
  .h-margin-m--60 {
    margin: 60px !important;
  }
  .h-margin-m__v--60 {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
  }
  .h-margin-m__h--60 {
    margin-left: 60px !important;
    margin-right: 60px !important;
  }
  .h-margin-m__b--60 {
    margin-bottom: 60px !important;
  }
  .h-margin-m__t--60 {
    margin-top: 60px !important;
  }
  .h-margin-m__l--60 {
    margin-left: 60px !important;
  }
  .h-margin-m__r--60 {
    margin-right: 60px !important;
  }
  .h-margin-m--70 {
    margin: 70px !important;
  }
  .h-margin-m__v--70 {
    margin-top: 70px !important;
    margin-bottom: 70px !important;
  }
  .h-margin-m__h--70 {
    margin-left: 70px !important;
    margin-right: 70px !important;
  }
  .h-margin-m__b--70 {
    margin-bottom: 70px !important;
  }
  .h-margin-m__t--70 {
    margin-top: 70px !important;
  }
  .h-margin-m__l--70 {
    margin-left: 70px !important;
  }
  .h-margin-m__r--70 {
    margin-right: 70px !important;
  }
  .h-margin-m--80 {
    margin: 80px !important;
  }
  .h-margin-m__v--80 {
    margin-top: 80px !important;
    margin-bottom: 80px !important;
  }
  .h-margin-m__h--80 {
    margin-left: 80px !important;
    margin-right: 80px !important;
  }
  .h-margin-m__b--80 {
    margin-bottom: 80px !important;
  }
  .h-margin-m__t--80 {
    margin-top: 80px !important;
  }
  .h-margin-m__l--80 {
    margin-left: 80px !important;
  }
  .h-margin-m__r--80 {
    margin-right: 80px !important;
  }
  .h-margin-m--90 {
    margin: 90px !important;
  }
  .h-margin-m__v--90 {
    margin-top: 90px !important;
    margin-bottom: 90px !important;
  }
  .h-margin-m__h--90 {
    margin-left: 90px !important;
    margin-right: 90px !important;
  }
  .h-margin-m__b--90 {
    margin-bottom: 90px !important;
  }
  .h-margin-m__t--90 {
    margin-top: 90px !important;
  }
  .h-margin-m__l--90 {
    margin-left: 90px !important;
  }
  .h-margin-m__r--90 {
    margin-right: 90px !important;
  }
  .h-margin-m--100 {
    margin: 100px !important;
  }
  .h-margin-m__v--100 {
    margin-top: 100px !important;
    margin-bottom: 100px !important;
  }
  .h-margin-m__h--100 {
    margin-left: 100px !important;
    margin-right: 100px !important;
  }
  .h-margin-m__b--100 {
    margin-bottom: 100px !important;
  }
  .h-margin-m__t--100 {
    margin-top: 100px !important;
  }
  .h-margin-m__l--100 {
    margin-left: 100px !important;
  }
  .h-margin-m__r--100 {
    margin-right: 100px !important;
  }
  .h-margin-m--200 {
    margin: 200px !important;
  }
  .h-margin-m__v--200 {
    margin-top: 200px !important;
    margin-bottom: 200px !important;
  }
  .h-margin-m__h--200 {
    margin-left: 200px !important;
    margin-right: 200px !important;
  }
  .h-margin-m__b--200 {
    margin-bottom: 200px !important;
  }
  .h-margin-m__t--200 {
    margin-top: 200px !important;
  }
  .h-margin-m__l--200 {
    margin-left: 200px !important;
  }
  .h-margin-m__r--200 {
    margin-right: 200px !important;
  }
  .h-margin-m--250 {
    margin: 250px !important;
  }
  .h-margin-m__v--250 {
    margin-top: 250px !important;
    margin-bottom: 250px !important;
  }
  .h-margin-m__h--250 {
    margin-left: 250px !important;
    margin-right: 250px !important;
  }
  .h-margin-m__b--250 {
    margin-bottom: 250px !important;
  }
  .h-margin-m__t--250 {
    margin-top: 250px !important;
  }
  .h-margin-m__l--250 {
    margin-left: 250px !important;
  }
  .h-margin-m__r--250 {
    margin-right: 250px !important;
  }
  .h-margin-m--300 {
    margin: 300px !important;
  }
  .h-margin-m__v--300 {
    margin-top: 300px !important;
    margin-bottom: 300px !important;
  }
  .h-margin-m__h--300 {
    margin-left: 300px !important;
    margin-right: 300px !important;
  }
  .h-margin-m__b--300 {
    margin-bottom: 300px !important;
  }
  .h-margin-m__t--300 {
    margin-top: 300px !important;
  }
  .h-margin-m__l--300 {
    margin-left: 300px !important;
  }
  .h-margin-m__r--300 {
    margin-right: 300px !important;
  }
  .h-margin-m--auto {
    margin: auto !important;
  }
}
.h-padding--0 {
  padding: 0px !important;
}
.h-padding__v--0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.h-padding__h--0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.h-padding__b--0 {
  padding-bottom: 0px !important;
}
.h-padding__t--0 {
  padding-top: 0px !important;
}
.h-padding__l--0 {
  padding-left: 0px !important;
}
.h-padding__r--0 {
  padding-right: 0px !important;
}
.h-padding--5 {
  padding: 5px !important;
}
.h-padding__v--5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.h-padding__h--5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.h-padding__b--5 {
  padding-bottom: 5px !important;
}
.h-padding__t--5 {
  padding-top: 5px !important;
}
.h-padding__l--5 {
  padding-left: 5px !important;
}
.h-padding__r--5 {
  padding-right: 5px !important;
}
.h-padding--8 {
  padding: 8px !important;
}
.h-padding__v--8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.h-padding__h--8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}
.h-padding__b--8 {
  padding-bottom: 8px !important;
}
.h-padding__t--8 {
  padding-top: 8px !important;
}
.h-padding__l--8 {
  padding-left: 8px !important;
}
.h-padding__r--8 {
  padding-right: 8px !important;
}
.h-padding--10 {
  padding: 10px !important;
}
.h-padding__v--10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.h-padding__h--10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.h-padding__b--10 {
  padding-bottom: 10px !important;
}
.h-padding__t--10 {
  padding-top: 10px !important;
}
.h-padding__l--10 {
  padding-left: 10px !important;
}
.h-padding__r--10 {
  padding-right: 10px !important;
}
.h-padding--15 {
  padding: 15px !important;
}
.h-padding__v--15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.h-padding__h--15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.h-padding__b--15 {
  padding-bottom: 15px !important;
}
.h-padding__t--15 {
  padding-top: 15px !important;
}
.h-padding__l--15 {
  padding-left: 15px !important;
}
.h-padding__r--15 {
  padding-right: 15px !important;
}
.h-padding--20 {
  padding: 20px !important;
}
.h-padding__v--20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.h-padding__h--20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}
.h-padding__b--20 {
  padding-bottom: 20px !important;
}
.h-padding__t--20 {
  padding-top: 20px !important;
}
.h-padding__l--20 {
  padding-left: 20px !important;
}
.h-padding__r--20 {
  padding-right: 20px !important;
}
.h-padding--25 {
  padding: 25px !important;
}
.h-padding__v--25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.h-padding__h--25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}
.h-padding__b--25 {
  padding-bottom: 25px !important;
}
.h-padding__t--25 {
  padding-top: 25px !important;
}
.h-padding__l--25 {
  padding-left: 25px !important;
}
.h-padding__r--25 {
  padding-right: 25px !important;
}
.h-padding--30 {
  padding: 30px !important;
}
.h-padding__v--30 {
  padding-top: 30px !important;
  padding-bottom: 30px !important;
}
.h-padding__h--30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.h-padding__b--30 {
  padding-bottom: 30px !important;
}
.h-padding__t--30 {
  padding-top: 30px !important;
}
.h-padding__l--30 {
  padding-left: 30px !important;
}
.h-padding__r--30 {
  padding-right: 30px !important;
}
.h-padding--40 {
  padding: 40px !important;
}
.h-padding__v--40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.h-padding__h--40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.h-padding__b--40 {
  padding-bottom: 40px !important;
}
.h-padding__t--40 {
  padding-top: 40px !important;
}
.h-padding__l--40 {
  padding-left: 40px !important;
}
.h-padding__r--40 {
  padding-right: 40px !important;
}
.h-padding--50 {
  padding: 50px !important;
}
.h-padding__v--50 {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
}
.h-padding__h--50 {
  padding-left: 50px !important;
  padding-right: 50px !important;
}
.h-padding__b--50 {
  padding-bottom: 50px !important;
}
.h-padding__t--50 {
  padding-top: 50px !important;
}
.h-padding__l--50 {
  padding-left: 50px !important;
}
.h-padding__r--50 {
  padding-right: 50px !important;
}
.h-padding--60 {
  padding: 60px !important;
}
.h-padding__v--60 {
  padding-top: 60px !important;
  padding-bottom: 60px !important;
}
.h-padding__h--60 {
  padding-left: 60px !important;
  padding-right: 60px !important;
}
.h-padding__b--60 {
  padding-bottom: 60px !important;
}
.h-padding__t--60 {
  padding-top: 60px !important;
}
.h-padding__l--60 {
  padding-left: 60px !important;
}
.h-padding__r--60 {
  padding-right: 60px !important;
}
.h-padding--70 {
  padding: 70px !important;
}
.h-padding__v--70 {
  padding-top: 70px !important;
  padding-bottom: 70px !important;
}
.h-padding__h--70 {
  padding-left: 70px !important;
  padding-right: 70px !important;
}
.h-padding__b--70 {
  padding-bottom: 70px !important;
}
.h-padding__t--70 {
  padding-top: 70px !important;
}
.h-padding__l--70 {
  padding-left: 70px !important;
}
.h-padding__r--70 {
  padding-right: 70px !important;
}
.h-padding--80 {
  padding: 80px !important;
}
.h-padding__v--80 {
  padding-top: 80px !important;
  padding-bottom: 80px !important;
}
.h-padding__h--80 {
  padding-left: 80px !important;
  padding-right: 80px !important;
}
.h-padding__b--80 {
  padding-bottom: 80px !important;
}
.h-padding__t--80 {
  padding-top: 80px !important;
}
.h-padding__l--80 {
  padding-left: 80px !important;
}
.h-padding__r--80 {
  padding-right: 80px !important;
}
.h-padding--90 {
  padding: 90px !important;
}
.h-padding__v--90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.h-padding__h--90 {
  padding-left: 90px !important;
  padding-right: 90px !important;
}
.h-padding__b--90 {
  padding-bottom: 90px !important;
}
.h-padding__t--90 {
  padding-top: 90px !important;
}
.h-padding__l--90 {
  padding-left: 90px !important;
}
.h-padding__r--90 {
  padding-right: 90px !important;
}
.h-padding--100 {
  padding: 100px !important;
}
.h-padding__v--100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}
.h-padding__h--100 {
  padding-left: 100px !important;
  padding-right: 100px !important;
}
.h-padding__b--100 {
  padding-bottom: 100px !important;
}
.h-padding__t--100 {
  padding-top: 100px !important;
}
.h-padding__l--100 {
  padding-left: 100px !important;
}
.h-padding__r--100 {
  padding-right: 100px !important;
}
.h-padding--200 {
  padding: 200px !important;
}
.h-padding__v--200 {
  padding-top: 200px !important;
  padding-bottom: 200px !important;
}
.h-padding__h--200 {
  padding-left: 200px !important;
  padding-right: 200px !important;
}
.h-padding__b--200 {
  padding-bottom: 200px !important;
}
.h-padding__t--200 {
  padding-top: 200px !important;
}
.h-padding__l--200 {
  padding-left: 200px !important;
}
.h-padding__r--200 {
  padding-right: 200px !important;
}
.h-padding--300 {
  padding: 300px !important;
}
.h-padding__v--300 {
  padding-top: 300px !important;
  padding-bottom: 300px !important;
}
.h-padding__h--300 {
  padding-left: 300px !important;
  padding-right: 300px !important;
}
.h-padding__b--300 {
  padding-bottom: 300px !important;
}
.h-padding__t--300 {
  padding-top: 300px !important;
}
.h-padding__l--300 {
  padding-left: 300px !important;
}
.h-padding__r--300 {
  padding-right: 300px !important;
}

@media (max-width: 700px) {
  .h-padding-m--0 {
    padding: 0px !important;
  }
  .h-padding-m__v--0 {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .h-padding-m__h--0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .h-padding-m__b--0 {
    padding-bottom: 0px !important;
  }
  .h-padding-m__t--0 {
    padding-top: 0px !important;
  }
  .h-padding-m__l--0 {
    padding-left: 0px !important;
  }
  .h-padding-m__r--0 {
    padding-right: 0px !important;
  }
  .h-padding-m--5 {
    padding: 5px !important;
  }
  .h-padding-m__v--5 {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }
  .h-padding-m__h--5 {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .h-padding-m__b--5 {
    padding-bottom: 5px !important;
  }
  .h-padding-m__t--5 {
    padding-top: 5px !important;
  }
  .h-padding-m__l--5 {
    padding-left: 5px !important;
  }
  .h-padding-m__r--5 {
    padding-right: 5px !important;
  }
  .h-padding-m--8 {
    padding: 8px !important;
  }
  .h-padding-m__v--8 {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }
  .h-padding-m__h--8 {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .h-padding-m__b--8 {
    padding-bottom: 8px !important;
  }
  .h-padding-m__t--8 {
    padding-top: 8px !important;
  }
  .h-padding-m__l--8 {
    padding-left: 8px !important;
  }
  .h-padding-m__r--8 {
    padding-right: 8px !important;
  }
  .h-padding-m--10 {
    padding: 10px !important;
  }
  .h-padding-m__v--10 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
  .h-padding-m__h--10 {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .h-padding-m__b--10 {
    padding-bottom: 10px !important;
  }
  .h-padding-m__t--10 {
    padding-top: 10px !important;
  }
  .h-padding-m__l--10 {
    padding-left: 10px !important;
  }
  .h-padding-m__r--10 {
    padding-right: 10px !important;
  }
  .h-padding-m--15 {
    padding: 15px !important;
  }
  .h-padding-m__v--15 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }
  .h-padding-m__h--15 {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
  .h-padding-m__b--15 {
    padding-bottom: 15px !important;
  }
  .h-padding-m__t--15 {
    padding-top: 15px !important;
  }
  .h-padding-m__l--15 {
    padding-left: 15px !important;
  }
  .h-padding-m__r--15 {
    padding-right: 15px !important;
  }
  .h-padding-m--20 {
    padding: 20px !important;
  }
  .h-padding-m__v--20 {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }
  .h-padding-m__h--20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .h-padding-m__b--20 {
    padding-bottom: 20px !important;
  }
  .h-padding-m__t--20 {
    padding-top: 20px !important;
  }
  .h-padding-m__l--20 {
    padding-left: 20px !important;
  }
  .h-padding-m__r--20 {
    padding-right: 20px !important;
  }
  .h-padding-m--25 {
    padding: 25px !important;
  }
  .h-padding-m__v--25 {
    padding-top: 25px !important;
    padding-bottom: 25px !important;
  }
  .h-padding-m__h--25 {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }
  .h-padding-m__b--25 {
    padding-bottom: 25px !important;
  }
  .h-padding-m__t--25 {
    padding-top: 25px !important;
  }
  .h-padding-m__l--25 {
    padding-left: 25px !important;
  }
  .h-padding-m__r--25 {
    padding-right: 25px !important;
  }
  .h-padding-m--30 {
    padding: 30px !important;
  }
  .h-padding-m__v--30 {
    padding-top: 30px !important;
    padding-bottom: 30px !important;
  }
  .h-padding-m__h--30 {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  .h-padding-m__b--30 {
    padding-bottom: 30px !important;
  }
  .h-padding-m__t--30 {
    padding-top: 30px !important;
  }
  .h-padding-m__l--30 {
    padding-left: 30px !important;
  }
  .h-padding-m__r--30 {
    padding-right: 30px !important;
  }
  .h-padding-m--40 {
    padding: 40px !important;
  }
  .h-padding-m__v--40 {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
  }
  .h-padding-m__h--40 {
    padding-left: 40px !important;
    padding-right: 40px !important;
  }
  .h-padding-m__b--40 {
    padding-bottom: 40px !important;
  }
  .h-padding-m__t--40 {
    padding-top: 40px !important;
  }
  .h-padding-m__l--40 {
    padding-left: 40px !important;
  }
  .h-padding-m__r--40 {
    padding-right: 40px !important;
  }
  .h-padding-m--50 {
    padding: 50px !important;
  }
  .h-padding-m__v--50 {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
  }
  .h-padding-m__h--50 {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }
  .h-padding-m__b--50 {
    padding-bottom: 50px !important;
  }
  .h-padding-m__t--50 {
    padding-top: 50px !important;
  }
  .h-padding-m__l--50 {
    padding-left: 50px !important;
  }
  .h-padding-m__r--50 {
    padding-right: 50px !important;
  }
  .h-padding-m--60 {
    padding: 60px !important;
  }
  .h-padding-m__v--60 {
    padding-top: 60px !important;
    padding-bottom: 60px !important;
  }
  .h-padding-m__h--60 {
    padding-left: 60px !important;
    padding-right: 60px !important;
  }
  .h-padding-m__b--60 {
    padding-bottom: 60px !important;
  }
  .h-padding-m__t--60 {
    padding-top: 60px !important;
  }
  .h-padding-m__l--60 {
    padding-left: 60px !important;
  }
  .h-padding-m__r--60 {
    padding-right: 60px !important;
  }
  .h-padding-m--70 {
    padding: 70px !important;
  }
  .h-padding-m__v--70 {
    padding-top: 70px !important;
    padding-bottom: 70px !important;
  }
  .h-padding-m__h--70 {
    padding-left: 70px !important;
    padding-right: 70px !important;
  }
  .h-padding-m__b--70 {
    padding-bottom: 70px !important;
  }
  .h-padding-m__t--70 {
    padding-top: 70px !important;
  }
  .h-padding-m__l--70 {
    padding-left: 70px !important;
  }
  .h-padding-m__r--70 {
    padding-right: 70px !important;
  }
  .h-padding-m--80 {
    padding: 80px !important;
  }
  .h-padding-m__v--80 {
    padding-top: 80px !important;
    padding-bottom: 80px !important;
  }
  .h-padding-m__h--80 {
    padding-left: 80px !important;
    padding-right: 80px !important;
  }
  .h-padding-m__b--80 {
    padding-bottom: 80px !important;
  }
  .h-padding-m__t--80 {
    padding-top: 80px !important;
  }
  .h-padding-m__l--80 {
    padding-left: 80px !important;
  }
  .h-padding-m__r--80 {
    padding-right: 80px !important;
  }
  .h-padding-m--90 {
    padding: 90px !important;
  }
  .h-padding-m__v--90 {
    padding-top: 90px !important;
    padding-bottom: 90px !important;
  }
  .h-padding-m__h--90 {
    padding-left: 90px !important;
    padding-right: 90px !important;
  }
  .h-padding-m__b--90 {
    padding-bottom: 90px !important;
  }
  .h-padding-m__t--90 {
    padding-top: 90px !important;
  }
  .h-padding-m__l--90 {
    padding-left: 90px !important;
  }
  .h-padding-m__r--90 {
    padding-right: 90px !important;
  }
  .h-padding-m--100 {
    padding: 100px !important;
  }
  .h-padding-m__v--100 {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
  .h-padding-m__h--100 {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }
  .h-padding-m__b--100 {
    padding-bottom: 100px !important;
  }
  .h-padding-m__t--100 {
    padding-top: 100px !important;
  }
  .h-padding-m__l--100 {
    padding-left: 100px !important;
  }
  .h-padding-m__r--100 {
    padding-right: 100px !important;
  }
  .h-padding-m--200 {
    padding: 200px !important;
  }
  .h-padding-m__v--200 {
    padding-top: 200px !important;
    padding-bottom: 200px !important;
  }
  .h-padding-m__h--200 {
    padding-left: 200px !important;
    padding-right: 200px !important;
  }
  .h-padding-m__b--200 {
    padding-bottom: 200px !important;
  }
  .h-padding-m__t--200 {
    padding-top: 200px !important;
  }
  .h-padding-m__l--200 {
    padding-left: 200px !important;
  }
  .h-padding-m__r--200 {
    padding-right: 200px !important;
  }
  .h-padding-m--300 {
    padding: 300px !important;
  }
  .h-padding-m__v--300 {
    padding-top: 300px !important;
    padding-bottom: 300px !important;
  }
  .h-padding-m__h--300 {
    padding-left: 300px !important;
    padding-right: 300px !important;
  }
  .h-padding-m__b--300 {
    padding-bottom: 300px !important;
  }
  .h-padding-m__t--300 {
    padding-top: 300px !important;
  }
  .h-padding-m__l--300 {
    padding-left: 300px !important;
  }
  .h-padding-m__r--300 {
    padding-right: 300px !important;
  }
}